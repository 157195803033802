import React, { useMemo, useEffect } from 'react';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { CustomModelClient } from '@marriott/mi-headless-utils';

import { OVERVIEW_PAGE_URL, SWEEPSTAKES_ENTRY_PAGE_URL, constants } from '@marriott/mi-account-components/constants';
import '@marriott/global-styles/dist/marriot.global.css';
import { defineComponentMapping } from '../import-components';
import { canUseDOM } from '@marriott/mi-ui-library';
import { BACKGROUND_COLOR_PAGE_ARRAY, PageContext } from '@marriott/mi-account-components';
import {
  UpdateDatalayerObj,
  setHWSReservationURL,
  setSubDirectoryPrefix,
  setRitzCarltonUrl,
} from '@marriott/mi-account-components/utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { loadLocale, loadLocaleCurrency, setSessionKey } from '@marriott/mi-book-components';
import path from 'path';
import 'react-loading-skeleton/dist/skeleton.css';
import '@splidejs/splide/css';

path.resolve('./next.config.js');
declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(useClientEnvVarsStore.getState().envVarsObject['NEXT_PUBLIC_AEM_HOST']);

if (useClientEnvVarsStore.getState().envVarsObject['NODE_ENV'] !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const {
    model,
    resolvedUrl,
    isAuthorMode,
    sessionData,
    sessionTimeOutPage = false,
    isEAA = false,
    isMobileAuthFeatureEnabled = false,
    isNonChinaMobileEnabled = false,
    sweepstakesPreprocessorResponse = {},
    isResetTokenValid,
    apolloEnvVars,
    userState,
    isDTT,
    isPointsTransferSuccess = false,
  } = pageProps;

  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  const serverENV = pageProps?.serverENV ? JSON.parse(pageProps?.serverENV) : {};
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['ACCEPT_LANGUAGE'] = pageProps.currentLocale;
  constants?.NEXT_PUBLIC_ENV_KEYS?.map(envKey => (envVars[envKey] = serverENV[envKey]));
  setEnvVars?.(envVars);
  let subDirectoryPrefix = '';
  if (model) {
    subDirectoryPrefix = model.subDirectoryPrefix ? model.subDirectoryPrefix : '';
    setSubDirectoryPrefix(subDirectoryPrefix);
    setHWSReservationURL(model?.hwsURL || '');
    setRitzCarltonUrl(model?.rcURL || '');
  }

  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode, isDTT);

  const pageContext = useMemo(() => {
    /** attache memo value */
    return {
      /**use only to read the values */
      sessionData: sessionData,
      sessionTimedOutPage: sessionTimeOutPage,
      isEAASignIn: isEAA,
      serverENV: pageProps?.serverENV,
      currentLocale: pageProps.currentLocale, // to get current locale
      isMobileAuthFeatureEnabled: isMobileAuthFeatureEnabled,
      isNonChinaMobileEnabled: isNonChinaMobileEnabled,
      isOverViewPage: pageProps?.requestUri?.toLowerCase() === OVERVIEW_PAGE_URL?.toLowerCase(),
      uxlErrorMessage:
        model?.uxlFailureErrorMessage ??
        '<strong>We’re having trouble fetching some data.</strong> Please try again in a moment.',
      memberStatusList: model?.memberStatusList,
      sweepstakesPreprocessorResponse: sweepstakesPreprocessorResponse,
      rsaPublicKey: pageProps.rsaPublicKey ? pageProps.rsaPublicKey : null,
      enrollAndLinkStatus: pageProps.enrollAndLinkStatus ? pageProps.enrollAndLinkStatus : null,
      isResetTokenValid: isResetTokenValid,
      userState: userState,
      nuaDetails: pageProps.nuaDetails ? pageProps.nuaDetails : null,
      enableTimer: model?.timerFlag,
      isPointsTransferSuccess: isPointsTransferSuccess,
      rtlEnable: model?.rtlEnable,
    };
  }, [sessionTimeOutPage]);

  useEffect(() => {
    const isPageFound =
      BACKGROUND_COLOR_PAGE_ARRAY.includes(pageProps?.requestUri) ||
      pageProps?.requestUri?.includes(SWEEPSTAKES_ENTRY_PAGE_URL);
    if (pageProps?.requestUri && isPageFound) {
      document.body.className += ' color-scheme3';
    }
    const dataLayerObj = window.dataLayer ?? {};
    UpdateDatalayerObj(dataLayerObj);
    // This is added as a part of NUA globalization as we are reusing book components. We are getting these labels from page level model which are required on below methods that are used for updating the locale, date formatting & currency symbol.
    if (model && pageProps.nuaDetails) {
      const {
        language = '',
        dateOrdinal = '',
        dateFormat = '',
        subDirectoryPrefix = '',
        localeCurrencySymbol = '',
      } = model;
      loadLocaleCurrency(localeCurrencySymbol);
      setSessionKey('SUBDIRECTORYCONTEXTPATH', subDirectoryPrefix);
      loadLocale(language, dateOrdinal, dateFormat);
    }
  }, []);

  return (
    <PageContext.Provider value={pageContext}>
      <Component {...pageProps} />
    </PageContext.Provider>
  );
};

export default App;
