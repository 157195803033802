// Imports for external libraries go here.
import { FC, useState, Suspense, ChangeEvent, useContext, useEffect, useRef } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { inspect } from 'util';
import { useLazyQuery } from '@apollo/client';

// Imports for internal (to the monorepo) libraries go here,
import { Calendar, Button, Messages, baseVariables, Heading, Types, Text } from '@marriott/mi-ui-library';
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import {
  NIGHTS_LABEL,
  NIGHT_LABEL,
  ALLOWED_DAY_THRESHOLD,
  ALLOWED_DAY_THRESHOLD_FIND_RESERVATION,
  CONFIRMATION_NUMBER_MAXLENGTH,
  findReservationDetailUrl,
  AllLocalesKeysListConstant,
  localeDateFormats,
  CONFIRM_CANCELLATION_URL,
  STATUS_CANCELLED,
} from '../../modules/utils/constants';
import { PageContext } from '../../modules/context/PageContext';
import { phoenixAccountDttGetReservationDetails } from '../../modules/graphV2';
import {
  apiLogger,
  generateApolloClientHeaders,
  addSubDirectoryPrefix,
  getCalednarLocaleKeys,
} from '../../modules/utils';
import { useStore } from '../../modules/store/memberLevelStore';
import { FindReservationProps, FormField, ReservationInput, reservationData } from './FindReservation.types';
import { StyledFindReservation } from './FindReservation.styles';
import { getOrderDetailsByOrderIdTransform } from './mapper';

// Use named rather than default exports.
export const FindReservationV2: FC<FindReservationProps> = props => {
  const { labels, onCancel, resLookupFromHeader } = props;
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const currentLocale = pageContext?.currentLocale ?? 'en_US';
  const showFindReservationModal = useStore(state => state.showFindReservationModal);
  const calendarInputRef = useRef<HTMLDivElement>(null);
  const [getOrderDetails] = useLazyQuery(phoenixAccountDttGetReservationDetails);

  const [showCheckInDate, setShowCheckInDate] = useState<boolean>(false);
  const [showWaringMsg, setShowWarningMsg] = useState<boolean>(false);
  const [selectedCheckInDate, setSelectedCheckInDate] = useState<Date | undefined>();
  const isNameSwap =
    currentLocale === AllLocalesKeysListConstant.cn ||
    currentLocale === AllLocalesKeysListConstant.ja ||
    currentLocale === AllLocalesKeysListConstant.ko;

  const firstNameFieldObj = {
    firstName: {
      id: 'firstName',
      label: labels?.firstName,
      errorMessage: labels?.firstNameErrorMessage,
      value: sessionData?.firstName ?? '',
      showErrorMsg: false,
    },
  };
  const lastNameFieldObj = {
    lastName: {
      id: 'lastName',
      label: labels?.lastName,
      errorMessage: labels?.lastNameErrorMessage,
      value: sessionData?.lastName ?? '',
      showErrorMsg: false,
    },
  };
  const [formFields, setFormFields] = useState<Record<string, FormField>>({
    confirmationNo: {
      id: 'confirmationNo',
      label: labels?.confirmationNumber,
      errorMessage: labels?.confirmationNumberErrorMessage,
      value: '',
      showErrorMsg: false,
    },
    checkInDate: {
      id: 'checkInDate',
      label: labels?.checkInDate,
      errorMessage: labels?.checkInDateErrorMessage,
      value: '',
      showErrorMsg: false,
    },
    ...(isNameSwap ? { ...lastNameFieldObj, ...firstNameFieldObj } : { ...firstNameFieldObj, ...lastNameFieldObj }),
  });

  useEffect(() => {
    if (window?.dataLayer && window?.dataLayer['chckInDat']) {
      const isCheckinDate = window?.dataLayer['chckInDat'];

      setSelectedCheckInDate(prevDate => {
        const newDate = moment(isCheckinDate).toDate();
        return newDate || prevDate;
      });

      setFormFields(prevState => ({
        ...prevState,
        checkInDate: {
          ...prevState['checkInDate'],
          value: moment(isCheckinDate).format(
            localeDateFormats[currentLocale?.replace('_', '-') ?? 'en-US']?.calendarView
          ),
          showErrorMsg: false,
        },
      }));
    }
    if (showFindReservationModal) {
      const fields = { ...formFields };
      Object.values(fields).forEach(ele => {
        fields[ele.id].value = '';
        fields[ele.id].showErrorMsg = false;
        if (ele.id === 'firstName' || ele.id === 'lastName') {
          fields[ele.id].value = sessionData?.[ele.id] ?? '';
        }
      });

      setShowCheckInDate(false);
      setShowWarningMsg(false);
      window.addEventListener('click', handleClickListener);
    }
    return () => {
      window.removeEventListener('click', handleClickListener);
    };
  }, [showFindReservationModal]);

  const handleClickListener = (event: Event) => {
    if (
      !calendarInputRef?.current?.contains(event.target as Element) &&
      !calendarInputRef?.current?.contains(document.activeElement)
    ) {
      setShowCheckInDate(false);
    }
  };

  // UXL call for get activity table data
  const validateReservationDetails = (input: ReservationInput) => {
    getOrderDetails({
      variables: {
        input: input,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache', //TODO need to review this
      // To do: update the type of the data variable for success calls
      onCompleted: (data: reservationData) => {
        const orderDetails = getOrderDetailsByOrderIdTransform(data?.commerce?.order);
        const orderId = orderDetails?.orderByFactor?.id;
        const propertyId = orderDetails?.orderByFactor?.items[0]?.property?.id;
        const location = props?.isOverLay ? window.parent.location : window.location;
        const tripStatus = orderDetails?.orderByFactor?.items[0]?.basicInformation?.status?.code;

        location.href = `${addSubDirectoryPrefix(
          tripStatus?.toLowerCase() === STATUS_CANCELLED ? CONFIRM_CANCELLATION_URL : findReservationDetailUrl
        )}?confirmationNumber=${orderId}&tripId=${orderId}&propertyId=${propertyId} `;
        apiLogger(`[FindReservation] getOrderDetails - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`);
      },
      onError: error => {
        setShowWarningMsg(true);
        const fields = { ...formFields };
        Object.values(fields).forEach(ele => {
          fields[ele.id].value = '';
        });
        setFormFields(fields);
        apiLogger(
          `[FindReservation] getOrderDetails - sessionId: ${sessionData?.sessionToken} - error: ${inspect(error)}`
        );
      },
    });
  };

  const dateFromDaysAgo = moment().subtract(ALLOWED_DAY_THRESHOLD_FIND_RESERVATION, 'days').toDate();
  const dateToDays = moment().add(ALLOWED_DAY_THRESHOLD, 'days').toDate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: FormField) => {
    const value = e.target.value;
    const { id } = field ?? {};
    const fields = { ...formFields };
    fields[id].value = value;
    fields[id].showErrorMsg = false;
    setFormFields(fields);
    setShowWarningMsg(false);
  };

  const handleDateChange = (selectedDate: Date) => {
    setSelectedCheckInDate(selectedDate);
    setFormFields(prevState => ({
      ...prevState,
      checkInDate: {
        ...prevState['checkInDate'],
        value: moment(selectedDate).format(
          localeDateFormats[currentLocale?.replace('_', '-') ?? 'en-US']?.calendarView
        ),
        showErrorMsg: false,
      },
    }));
    setShowCheckInDate(false);
  };

  const handleSubmit = () => {
    const fields = { ...formFields };
    let isformValid = true;
    Object.values(fields)?.forEach((element: FormField) => {
      if (!element.value) {
        isformValid = false;
        fields[element.id].showErrorMsg = true;
      }
    });

    setFormFields(fields);
    setShowWarningMsg(false);
    if (isformValid) {
      const requestData = {
        firstName: fields['firstName'].value,
        lastName: fields['lastName'].value,
        confirmationNumber: fields['confirmationNo'].value,
        startDate: moment(selectedCheckInDate).format('YYYY-MM-DD'),
        includeSiblingReservations: false,
      };
      validateReservationDetails(requestData);
    }
  };

  const WarningMessageElement = () => (
    <div dangerouslySetInnerHTML={{ __html: labels?.warningMessageText ?? '' }}></div>
  );
  const { shortWeekdays, shortWeekDaysName, longWeekDaysName, longMonthName, shortMonthName } =
    getCalednarLocaleKeys(labels);

  return (
    <StyledFindReservation
      data-component-name="m-account-FindReservation"
      data-testid="account-FindReservation"
      className={clsx('pt-2 color-scheme7', resLookupFromHeader && 'res-lookup-container')}
    >
      {resLookupFromHeader && (
        <Heading
          variation={Types.headingType.title}
          fontSize={Types.size.small}
          element={Types.tags.h3}
          titleText={labels.title}
          disableCustomClickTrack={true}
        />
      )}
      <Text
        copyText={labels?.subTitle}
        fontSize={resLookupFromHeader ? Types.size.small : Types.size.medium}
        element={Types.tags.paragraph}
        customClass={clsx('t-label-m m-0', resLookupFromHeader ? 'p-0 pt-3' : 'px-4 pt-3 px-md-5 pt-md-5')}
      />

      {showWaringMsg && (
        <Messages
          messageType="error-sev1"
          className={clsx('pt-3', !resLookupFromHeader && 'px-5')}
          children={<WarningMessageElement />}
        />
      )}
      <div className={clsx('row pb-2', resLookupFromHeader ? 'px-0' : 'px-5')}>
        {Object.values(formFields)?.map(field => {
          const isCheckInDateField = field.id === 'checkInDate';

          return (
            <div
              className={clsx('mt-5 pt-2 px-md-2', resLookupFromHeader ? 'col-12' : 'col-12 col-md-6')}
              ref={isCheckInDateField ? calendarInputRef : null}
              key={field.id}
            >
              <div className={clsx('m-input-field ', field.showErrorMsg ? 'is-error' : 'is-active')}>
                <label htmlFor={field.id}>{field.label}</label>
                <input
                  type="text"
                  data-testid={field.id}
                  name={field.id}
                  id={field.id}
                  style={{ caretColor: baseVariables.color['base10'] }}
                  value={field.value}
                  onChange={e => handleChange(e, field)}
                  onClick={() => isCheckInDateField && setShowCheckInDate(true)}
                  onKeyDown={() => isCheckInDateField && setShowCheckInDate(true)}
                  autoComplete="off"
                  readOnly={isCheckInDateField}
                  role="textbox"
                  maxLength={field.id === 'confirmationNo' ? CONFIRMATION_NUMBER_MAXLENGTH : undefined}
                />
                {field.showErrorMsg && <span>{field.errorMessage}</span>}
                {isCheckInDateField && (
                  <span
                    data-testid="calendarDay"
                    className={resLookupFromHeader ? 'icon-arrow-down icon-xs' : 'icon-calendar icon-m'}
                    onClick={() => setShowCheckInDate(true)}
                  ></span>
                )}
              </div>
              {showCheckInDate && isCheckInDateField && (
                <Suspense fallback={<>loading</>}>
                  <div
                    className={clsx('calendar-wrapper mb-3', resLookupFromHeader ? 'calendar-wrapper-position' : '')}
                  >
                    <Calendar
                      variation={'DESKTOP'}
                      startDate={moment(selectedCheckInDate)}
                      endDate={null}
                      focusedInput={'customDate'}
                      setFocusedInputHandler={(newState): void => {
                        console.log('setFocusedInputHandler', newState);
                      }}
                      customDatesFlag={true}
                      nightsLabel={NIGHTS_LABEL}
                      nightLabel={NIGHT_LABEL}
                      maxAllowedDays={7}
                      weekdays={shortWeekdays}
                      weekdaysLong={longWeekDaysName}
                      monthsShort={shortMonthName}
                      weekdaysShort={shortWeekDaysName}
                      disableHoverToolTip={true}
                      months={longMonthName}
                      defaultMonth={new Date()}
                      disabledDays={[]}
                      onDayClickEvent={day => handleDateChange(day)}
                      customFromDate={dateFromDaysAgo}
                      customToDate={dateToDays}
                      numberOfMonths={1}
                      firstDayOfWeek={labels.firstDayOfWeek ?? '0'}
                      showOutsideDays={true}
                      setDateHandler={(startDate, endDate) => {
                        console.log(startDate, endDate);
                      }}
                    />
                  </div>
                </Suspense>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={clsx(
          'mt-5 ',
          resLookupFromHeader
            ? ''
            : 'd-flex align-items-center flex-md-row flex-column-reverse overlay-footer justify-content-end  py-4'
        )}
      >
        {!resLookupFromHeader && (
          <Button className="t-font-s cancel-button mr-0 p-0" callback={onCancel}>
            {labels.cancelButtonLabel}
          </Button>
        )}
        <Button
          className={clsx(
            'm-button-primary width-mobile-100 mb-md-0 py-3 py-sm-2',
            resLookupFromHeader ? 'mx-0' : 'mx-5 mb-4'
          )}
          testId="button-primary"
          callback={handleSubmit}
        >
          {labels.findButtonLabel}
        </Button>
      </div>
    </StyledFindReservation>
  );
};
