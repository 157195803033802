// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const AddCard = dynamic(() => import('./AddCard').then(mod => mod.AddCard));
const AddCardV2 = dynamic(() => import('./AddCardV2').then(mod => mod.AddCardV2));

export const AddCardConfig = {
  emptyLabel: 'addcard',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/addcard`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AddCardEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    props?.isDTT ? (
      <AddCardV2 {...props} />
    ) : (
      <AddCard {...props} />
    )
  ) : (
    <EditableComponent config={AddCardConfig} {...props}>
      {props?.isDTT ? <AddCardV2 {...props} /> : <AddCard {...props} />}
    </EditableComponent>
  );
};
