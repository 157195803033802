/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createAppStore } from '@marriott/mi-store-utils';
import { PLATINUM_CORE_CODE } from '../utils';

export interface memberDataState {
  customer: {
    profile: {
      name: {
        givenName: string;
      };
    };
    loyaltyInformation: {
      rewards: {
        datePointsExpire: string;
        currentPointBalance: number;
        eliteLifetimeNights?: number;
        number: number;
        eliteNightsToAchieveNext: number;
        eliteNightsToRenewCurrent: number;
        eliteLifetimeLevel: {
          code: string;
        };
        level: {
          code: string;
        };
        levelType: {
          code: string;
        };
        nextLevelType: {
          code: string;
        };
        nextLevel: {
          code: string;
        };
      };
      rewardsSummary: {
        yearly: {
          totalNights: number;
          stayNights?: number;
          year: number;
          totalRevenue: number;
        }[];
      };
    };
    contactInformation?: {
      emails: {
        address: string;
        primary: boolean;
      }[];
      phones?: {
        country?: {
          code?: string;
        };
        number?: string;
      };
    };
  };
}

export interface memberDataUxlState {
  currentPoints: number | undefined;
  numberOfNights: number | undefined;
  memberLevelCode: string;
  memberLevelTypeCode: string;
  givenName: string;
}

export interface AccountPersistentState {
  memberLevel: string;
  memberData: memberDataState | null;
  memberLevelCode: string;
  memberLevelTypeCode: string;
  memberName: string;
  currentPointBalance: number | undefined;
  totalNightCount: number | undefined;
  setMemberDetails?: (value: memberDataState) => void;
  setMemberDetailsUxl?: (value: memberDataUxlState) => void;
  communicationOption?: string;
  setCommunicationOption?: (value: string) => void;
  setCurrentPointsBalance?: (value: number) => void;
  setMemberData?: (value: memberDataState | null) => void;
  loginEventDetails?: string;
  setLoginEventDetails?: (value: string) => void;
}

const initialState: AccountPersistentState = {
  memberLevel: '',
  memberData: null,
  memberLevelCode: '',
  memberLevelTypeCode: '',
  memberName: '',
  currentPointBalance: undefined,
  totalNightCount: undefined,
  loginEventDetails: '',
  setLoginEventDetails: () => {},
  setMemberDetails: () => {},
  setMemberDetailsUxl: () => {},
  communicationOption: '',
  setCommunicationOption: () => {},
  setCurrentPointsBalance: () => {},
};

const AccountPersistentStore: StateCreator<AccountPersistentState> = set => {
  return {
    ...initialState,
    setMemberDetails: (value: memberDataState) => {
      const memberRibbon = value?.customer;
      const memberCode = memberRibbon?.loyaltyInformation?.rewards?.level?.code;
      const memberLevelType = memberRibbon?.loyaltyInformation?.rewards?.levelType?.code;
      const name = memberRibbon?.profile?.name?.givenName;
      const pointBalance = memberRibbon?.loyaltyInformation?.rewards?.currentPointBalance;
      const nightCount = memberRibbon?.loyaltyInformation?.rewardsSummary?.yearly?.[0]?.totalNights;

      set({
        memberData: value,
        memberLevelCode: memberCode,
        memberLevelTypeCode: memberLevelType,
        memberName: name,
        currentPointBalance: pointBalance,
        totalNightCount: nightCount,
        memberLevel: memberCode?.toUpperCase() === PLATINUM_CORE_CODE && memberLevelType ? memberLevelType : memberCode,
      });
    },

    setMemberDetailsUxl: (data: memberDataUxlState) => {
      set({
        memberLevelCode: data?.memberLevelCode,
        memberLevelTypeCode: data?.memberLevelTypeCode,
        memberName: data?.givenName,
        currentPointBalance: Number(data?.currentPoints ?? ''),
        totalNightCount: Number(data?.numberOfNights ?? ''),
        memberLevel:
          data?.memberLevelCode?.toUpperCase() === PLATINUM_CORE_CODE && data?.memberLevelTypeCode
            ? data?.memberLevelTypeCode
            : data?.memberLevelCode,
      });
    },

    setCommunicationOption: (value: string) => {
      set({ communicationOption: value });
    },

    setCurrentPointsBalance: (value: number) => {
      set({ currentPointBalance: value });
    },

    setLoginEventDetails: (value: string) => {
      set({ loginEventDetails: value });
    },

    setMemberData: (value: memberDataState | null) => {
      set({ memberData: value });
    },
  };
};

export const useAccountPersistentStore = createAppStore(AccountPersistentStore, {
  usePersistentStore: true,
  persistentStoreName: 'AccountPersistentState',
});
