// Imports for external libraries go here.
import React, { FC } from 'react';
import dynamic from 'next/dynamic';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { OneClickJoinContainerProps } from './OneClickJoinContainer.types';
import { StyledOneClickJoinContainer } from './OneClickJoinContainer.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
const OneClickJoinForm = dynamic(() =>
  import('../../molecules/OneClickJoinForm').then(module => module.OneClickJoinForm)
);

// Use named rather than default exports.
export const OneClickJoinContainer: FC<OneClickJoinContainerProps> = ({ model, isDTT, isAuthorMode }) => {
  return (
    <StyledOneClickJoinContainer
      data-testid="oneclickjoinreservation"
      data-component-name="o-account-oneclickjoinreservation"
    >
      <OneClickJoinForm authorModelData={model} isDTT={isDTT} isAuthorMode={isAuthorMode} />
    </StyledOneClickJoinContainer>
  );
};

export const OneClickJoinContainerConfig = {
  emptyLabel: 'OneClickJoinContainer',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/oneclickjoincontainer`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OneClickJoinContainerEditable = (props: any) => {
  return (
    <EditableComponent config={OneClickJoinContainerConfig} {...props}>
      <OneClickJoinContainer {...props} />
    </EditableComponent>
  );
};
