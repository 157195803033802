// Styles for AccountOverviewNotification go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../modules';

export const StyledEmailUnsubscribe = styled.div`
  ${rtl`
  .unsubscribe {
    &__body:is(${constants.RTL_LANG_LIST}) {
      text-align: right !important;
    }
    &__email {
      max-width: unset;
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(343)};
      }
      
      :is(${constants.RTL_LANG_LIST}) {
        margin-left:auto;
        label {
          right: ${toRem(8)} !important;
        }
        input {
          padding-right: ${toRem(16)};
        }
      }
    }
    &__consents {
      .consent-bg {
        background-color: ${baseVariables.color['neutral20']};
        margin-left: auto;
      }

      &__container {
        :is(${constants.RTL_LANG_LIST}) {
          label {
            padding-right: ${toRem(24)};
            ::before {
              left: unset;
              right: 0;
            }

            ::after {
              left: unset;
              right: ${toRem(6)};
            }
          }
        }
        &__hrLine {
          border-top: ${toRem(1)} ${baseVariables.color['neutral20']} solid;
          color: ${baseVariables.color['neutral20']} !important;
        }
        &__checkbox {
          &__labelText {
            position: relative;
            bottom: ${toRem(4)};
          }
          label {
            padding-left: ${toRem(24)} !important;
            margin-bottom: 0;
          }
        }
      }
      }
    }
    &__errormsg {
      p {
        margin-bottom: 0 !important;
      }
    }
  } 
  .modal-footer {
    &__unsubscribe {
      width: inherit;
      @media ${baseVariables.mediaQuery.md} {
        width: max-content;
      }
    }
  }
  .m-message-content{
    p{
       margin-bottom: 0 !important;
    }
  }
  .unsubscribe:is(${constants.RTL_LANG_LIST}) {
    &__radiobtn{
      padding-right: ${toRem(24)} !important;
      ::before {
        left: unset !important;
        right: 0;
        display: flex;
      }
      ::after {
        left: unset;
        right: ${toRem(8)};
      }
    }
    &__footer{
      display: flex !important;
      flex-direction: column !important;
      @media ${baseVariables.mediaQuery.md} {
        flex-direction: row-reverse !important;
      }
    }
  }
`}
`;
