import { canUseDOM } from './helper';

export enum DATALAYER_PAGE_EVENT {
  EARNING_PREFERENCE_SUCCESS = 'earning-preference-success',
  CONVERT_POINTS_SUCCESS = 'convert-points-success',
  CONVERT_POINTS_FAILURE = 'convert-points-failure',
  LOGIN_SUCCESS = 'login-success',
  LOGIN_FAILURE = 'login-failure',
}

let ACCOUNT_DATALAYER_COUNTER: number = 0;
const updateDataLayerCounter = () => {
  ACCOUNT_DATALAYER_COUNTER = ACCOUNT_DATALAYER_COUNTER + 1;
};

export const updateDataLayerProperties = (dataLayerProperties: { [key: string]: unknown }, pageEvent: string) => {
  if (canUseDOM) {
    const dataLayer = window.dataLayer ?? {};
    Object.keys(dataLayerProperties).forEach(key => {
      dataLayer[key] = dataLayerProperties[key];
    });
    // update counter before updating hash
    updateDataLayerCounter();
    // Update the window.location.hash with the ACCOUNT_DATALAYER_COUNTER
    window.history.replaceState({}, '', `#page_name=${pageEvent}-${ACCOUNT_DATALAYER_COUNTER}`);
  }
};
