// Imports for external libraries go here.
import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { inspect } from 'util';
import { Modal, Heading, Types, Text, Messages, RichText, CheckBox } from '@marriott/mi-ui-library';
import { useProfileStore } from '../../modules/store/profileStore';
import {
  phoenixAccountAddCustomerPreferredLanguage,
  phoenixAccountGetConsentsByCustomerId,
  phoenixAccountUpdateCustomerConsents,
  phoenixAccountAddCustomerFeaturesConsent,
} from '../../modules/graph';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { apiLogger, generateApolloClientHeaders, getCurrentUrlParams, scrollToClass } from '../../modules/utils';
import {
  constants,
  KOREA_COUNTRY_CODE,
  KOREAN_PERSONAL_CODES,
  COMMUNICATION_CLICK_TRACK,
} from '../../modules/utils/constants';
import { PageContext } from '../../modules/context/PageContext';
import {
  BANNER_MSGS_KEYS,
  COMMUNICATION_PREFERENCE,
  COMMUNICATION_SUBSCRIBED,
  KOREAN_CONSENT_FORM_FEATURE_CODE,
  KOREAN_CONSENT_FORM_FEATURE_CODE_VALUE,
  KOREAN_CONSENT_FORM_FEATURE_CODE_GET,
  KOREAN_CONSENT_FORM_FEATURE_CODE_DENIED_VALUE,
} from '../../modules/utils/constants';
import { Dropdown } from '../../molecules/Dropdown';
import { StyledCommunicationPreference } from './CommunicationPreference.styles';
import {
  CommunicationOption,
  CommunicationPreferenceProps,
  LanguageDropdownOption,
} from './CommunicationPreference.types';
import consentData from './__mock__/CommunicationPreference.model.json';
import DOMPurify from 'isomorphic-dompurify';

export const CommunicationPreference = (props: CommunicationPreferenceProps) => {
  const isAuthorMode = props?.isAuthorMode;
  const {
    profileModalId,
    setProfileModalId,
    setProfileDetails,
    profileDetails,
    setRevisionToken,
    revisionToken,
    koreaUserConsents,
    consentShowLessLabe,
    consentShowMoreLabe,
  } = useProfileStore(state => state);
  const { IS_LOCAL_DEV } = process.env;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const modalBodyClass = 'communication-preferences-modal-body';
  const [hasError, setHasError] = useState<boolean>(false);
  const [isConsentError, setIsConsentError] = useState<boolean>(false);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);

  //TODO: revision token to be taken from useStore
  const [savedConsents, setSavedConsents] = useState<Array<CommunicationOption>>([]);
  const userProfileCountryCode = profileDetails?.profileAddress?.filter(address => address?.primary === true)?.[0];
  const userCountryCode = userProfileCountryCode?.address?.country?.code;
  const savedInitalConsentPayload: CommunicationOption[] = [];
  const [preferredLanguage, setPreferredLanguage] = useState<LanguageDropdownOption | undefined>(undefined);
  const [saveConsentsPayload, setSavedConsentsPayload] = useState<Array<CommunicationOption>>([]);
  const [showMoreStates, setShowMoreStates] = useState<{ [key: string]: boolean }>({});
  const isPersonnelConsentsChangesForKRUser = useRef(false);
  const [isPrivacyConsentAlreadyRegistered, setIsPrivacyConsentAlreadyRegistered] = useState(false);
  const [personalConsent, setPersonalConsent] = useState(
    KOREAN_PERSONAL_CODES.map(data => {
      return { type: data, value: false };
    })
  );
  /**
   * graphql calls
   */
  const [getCustomerCommunicationPreferences] = useLazyQuery(phoenixAccountGetConsentsByCustomerId);
  const [updateCustomerConsents] = useMutation(phoenixAccountUpdateCustomerConsents);
  const [updateLanguageConsent] = useMutation(phoenixAccountAddCustomerPreferredLanguage);
  const [updateCustomerFeatures] = useMutation(phoenixAccountAddCustomerFeaturesConsent);

  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isCommunicationPreferenceModal = param.get('editEmailPreferences');
    if (isCommunicationPreferenceModal === 'true') {
      setProfileModalId(COMMUNICATION_PREFERENCE);
    }

    if (isAuthorMode) {
      if (consentData?.data?.customer?.communicationOptions) {
        const initialSavedConsents = consentData?.data?.customer?.communicationOptions.filter(
          (value: CommunicationOption) =>
            COMMUNICATION_SUBSCRIBED.some(
              code => value?.type?.code === code?.type && value?.method?.code === code?.method
            )
        );
        setSavedConsents(initialSavedConsents);
        setSavedConsentsPayload(initialSavedConsents); // Set the initial payload with the same data
      }
      setPreferredLanguage({
        id: consentData?.data?.customer?.profile?.preferredLanguage?.code,
        value: consentData?.data?.customer?.profile?.preferredLanguage?.description,
      });
    }
  }, []);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === COMMUNICATION_PREFERENCE) {
      /**
       * get customer feature data on every render for communication modal
       * removed below code
       *  && savedConsents.length === 0
       */
      fetchCommunicationPreferences();
    }
    return () => {
      /**
       * reset all the state on close
       */
      isPersonnelConsentsChangesForKRUser.current = false;
      setIsConsentError(false);
      setHasError(false);
    };
  }, [profileModalId]);

  //initial data saved
  if (savedConsents) {
    savedInitalConsentPayload?.push(...savedConsents);
  }
  // handle the consent changes
  const handleDropdownChange = (selectedOption: LanguageDropdownOption) => {
    setPreferredLanguage(selectedOption);
  };
  const handleCheckboxChange = (option: { method: string; type: string }) => {
    setSavedConsentsPayload(prevConsents =>
      prevConsents?.map(consent =>
        consent.type.code === option.type && consent.method.code === option.method
          ? { ...consent, subscribed: !consent?.subscribed }
          : consent
      )
    );
  };
  const findSavedConsents = (code: { method: string; type: string }) => {
    if (saveConsentsPayload) {
      return saveConsentsPayload.find(
        (consent: { type: { code: string }; method: { code: string } }) =>
          consent?.type?.code === code?.type && consent?.method?.code === code?.method
      );
    }
    return null;
  };
  const hasConsentsChanged = (newOptions: CommunicationOption[], oldOptions: CommunicationOption[]) => {
    return newOptions.some(newOption => {
      const matchingOption = oldOptions.find(
        oldOption => newOption?.type.code === oldOption?.type.code && newOption?.method.code === oldOption?.method.code
      );
      return matchingOption && newOption?.subscribed !== matchingOption?.subscribed;
    });
  };
  const getLanguageChanged = (
    initialPreferredLanguage: string | undefined,
    prefLang: LanguageDropdownOption | undefined
  ) => {
    return (initialPreferredLanguage ?? '') !== prefLang?.id;
  };

  const clearModalStates = () => {
    setHasError(false);
    setProfileModalId('');
    setSavedConsents([]);
    setSavedConsentsPayload([]);
    setIsConsentError(false);
    isPersonnelConsentsChangesForKRUser.current = false;
    setPersonalConsent(
      KOREAN_PERSONAL_CODES?.map(data => {
        return { type: data, value: false };
      })
    );
  };
  const resetCommunicationModal = () => {
    setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
    clearModalStates();
  };

  //********uxl*************

  const fetchCommunicationPreferences = () => {
    getCustomerCommunicationPreferences({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data?.customer?.revisionToken) {
          setRevisionToken(data?.customer?.revisionToken);
        }
        setSavedConsents(
          data?.customer?.communicationOptions.filter((option: CommunicationOption) =>
            COMMUNICATION_SUBSCRIBED.some(
              subscribedOption =>
                option?.method?.code === subscribedOption?.method && option?.type?.code === subscribedOption?.type
            )
          )
        );
        if (
          data?.customer?.featuresByGroup?.privacyConsentFeatures?.findIndex(
            (data: { feature: { code: string }; value: string }) =>
              data.feature.code === KOREAN_CONSENT_FORM_FEATURE_CODE_GET &&
              data.value === KOREAN_CONSENT_FORM_FEATURE_CODE_VALUE
          ) !== -1
        ) {
          setIsPrivacyConsentAlreadyRegistered(true);
          setPersonalConsent(
            KOREAN_PERSONAL_CODES?.map(data => {
              return { type: data, value: true };
            })
          );
        }
        setSavedConsentsPayload(
          data?.customer?.communicationOptions?.map((value: CommunicationOption) => {
            return {
              method: {
                code: value?.method?.code,
              },
              subscribed: value?.subscribed,
              type: {
                code: value?.type?.code,
              },
            };
          })
        );
        setPreferredLanguage({
          id: profileDetails?.preferredLanguage?.code ?? '',
          value: findLanguage(profileDetails?.preferredLanguage?.code ?? '')?.value ?? '',
        });
        setHasError(false);
        apiLogger(
          `[CommunicationConsent] getSavedConsentData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError(true);
        scrollToClass(modalBodyClass);
      },
    });
  };

  //**********mutation*************

  const updateCommuncationAndLanguagePref = (shouldUpdateLanguage: boolean) => {
    updateCustomerConsents({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          revisionToken: revisionToken,
          options: saveConsentsPayload,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        setRevisionToken(data?.updateCustomerCommunicationOptions?.revisionToken);
        setProfileDetails({
          ...profileDetails,
          communicationOptions: saveConsentsPayload,
        });
        if (shouldUpdateLanguage) {
          updateLanguageConsent({
            variables: {
              input: {
                id: sessionData?.consumerID,
                revisionToken: data?.updateCustomerCommunicationOptions?.revisionToken,
                personalInformation: {
                  preferredLanguage: { code: preferredLanguage?.id },
                },
              },
            },
            context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
            onCompleted: data => {
              setRevisionToken(data?.updateCustomerPersonalInformation?.revisionToken);
              setProfileDetails({
                ...profileDetails,
                preferredLanguage: { code: preferredLanguage?.id || defaultLanguageOption.id },
                communicationOptions: saveConsentsPayload,
              });
              setHasError(false);
              apiLogger(
                `[CommunicationConsent] updateLanguageConsent - sessionId value: ${
                  sessionData?.sessionToken
                }: ${inspect(data)}`
              );
              if (isPersonnelConsentsChangesForKRUser.current) {
                /**
                 * update consent form is there is any change
                 */
                updateConsentDataForKrUSer();
              } else {
                resetCommunicationModal();
              }
            },
            onError: () => {
              setHasError(true);
              scrollToClass(modalBodyClass);
            },
          });
        } else {
          setProfileDetails({
            ...profileDetails,
            communicationOptions: saveConsentsPayload,
          });
          if (isPersonnelConsentsChangesForKRUser.current) {
            /**
             * update consent form is there is any change
             */
            updateConsentDataForKrUSer();
          } else {
            resetCommunicationModal();
          }
        }

        apiLogger(
          `[CommunicationConsent] updateCustomerConsents - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
      },
      onError: () => {
        setHasError(true);
        scrollToClass(modalBodyClass);
      },
    });
  };

  const checkIfAnyPersonalConsentCheckboxSelected = () => {
    return personalConsent?.filter(data => !data.value)?.length;
  };

  const onPersonnelConsentFormChange = (code: string) => {
    const personalConsentData = [...personalConsent];
    const index = personalConsentData.findIndex(data => {
      return data.type === code;
    });
    personalConsentData[index].value = !personalConsentData[index]?.value;
    isPersonnelConsentsChangesForKRUser.current = true;
    setIsConsentError(false);
    setHasError(false);
    setPersonalConsent(personalConsentData);
  };

  const updateConsentDataForKrUSer = () => {
    /**
     * update personnel consent check box
     */
    updateCustomerFeatures({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          customerFeatures: [
            {
              code: KOREAN_CONSENT_FORM_FEATURE_CODE,
              value: !checkIfAnyPersonalConsentCheckboxSelected()
                ? KOREAN_CONSENT_FORM_FEATURE_CODE_VALUE
                : KOREAN_CONSENT_FORM_FEATURE_CODE_DENIED_VALUE,
            },
          ],
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        isPersonnelConsentsChangesForKRUser.current = false;
        apiLogger(
          `[KOREAConsentFormCommunication] updateCustomerFeatures - sessionId value: ${
            sessionData?.sessionToken
          }: ${inspect(data)}`
        );
        resetCommunicationModal();
      },
      onError: error => {
        isPersonnelConsentsChangesForKRUser.current = false;
        setHasError(true);
        scrollToClass(modalBodyClass);
        apiLogger(
          `[KOREAConsentFormCommunication] updateCustomerFeatures - sessionId value: ${
            sessionData?.sessionToken
          } - error: ${inspect(error)}`
        );
      },
    });
  };

  const updatePayload = () => {
    // Check if there are any changes in consents
    if (userCountryCode === KOREA_COUNTRY_CODE) {
      if (
        isPersonnelConsentsChangesForKRUser.current &&
        checkIfAnyPersonalConsentCheckboxSelected() !== 0 &&
        checkIfAnyPersonalConsentCheckboxSelected() !== 3
      ) {
        /**
         * if partially selected by user
         * set error
         */
        setIsConsentError(true);
        return;
      } else if (
        isPrivacyConsentAlreadyRegistered &&
        isPersonnelConsentsChangesForKRUser.current &&
        checkIfAnyPersonalConsentCheckboxSelected() === 0
      ) {
        /**
         * user changed it but didn't update the overall response
         */
        isPersonnelConsentsChangesForKRUser.current = false;
        setIsConsentError(false);
      } else if (
        isPrivacyConsentAlreadyRegistered &&
        checkIfAnyPersonalConsentCheckboxSelected() === 3 &&
        isPersonnelConsentsChangesForKRUser.current
      ) {
        /**
         * user want to unselect consent checkboxes
         */
        setIsConsentError(false);
      }
    }
    const hasConsentsChanges = hasConsentsChanged(saveConsentsPayload, savedConsents);
    const hasLanguageChanges = getLanguageChanged(profileDetails?.preferredLanguage?.code, preferredLanguage);
    if (hasConsentsChanges && hasLanguageChanges) {
      updateCommuncationAndLanguagePref(true);
    } else if (hasConsentsChanges) {
      updateCommuncationAndLanguagePref(false);
    } else if (hasLanguageChanges) {
      updateLanguageConsent({
        variables: {
          input: {
            id: sessionData?.consumerID,
            revisionToken: revisionToken,
            personalInformation: {
              preferredLanguage: { code: preferredLanguage?.id },
            },
          },
        },
        context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
        onCompleted: data => {
          setProfileDetails({
            ...profileDetails,
            preferredLanguage: { code: preferredLanguage?.id || defaultLanguageOption.id },
          });
          apiLogger(
            `[CommunicationConsent] updateLanguageConsent - sessionId value: ${sessionData?.sessionToken}: ${inspect(
              data
            )}`
          );
          if (isPersonnelConsentsChangesForKRUser.current) {
            /**
             * update consent form is there is any change
             */
            updateConsentDataForKrUSer();
          } else {
            resetCommunicationModal();
          }
          setRevisionToken(data?.updateCustomerPersonalInformation?.revisionToken);
        },
        onError: () => {
          setHasError(true);
          scrollToClass(modalBodyClass);
        },
      });
    } else if (isPersonnelConsentsChangesForKRUser.current) {
      updateConsentDataForKrUSer();
    } else {
      resetCommunicationModal();
    }
  };
  const languageDropdownOptions = useMemo(() => {
    return props?.model?.languagePreferencesOptions?.map(option => ({
      id: option.itemKey,
      value: option.itemValue,
    }));
  }, [props?.model?.languagePreferencesOptions]);

  const findLanguage = (langCode: string) => languageDropdownOptions?.find(item => item.id === langCode);

  const defaultLanguageOption = useMemo(() => {
    // Attempt to find the language option based on the country code
    const languageOption =
      userCountryCode !== constants.USA_COUNTRY_CODE
        ? findLanguage(constants.BRITISH_LANG)
        : findLanguage(constants.DEFAULT_LANG);

    // Return the found language option or a default object with id and value properties
    return languageOption || { id: '', value: '' };
  }, [userCountryCode, languageDropdownOptions]);

  return (
    <StyledCommunicationPreference
      data-testid="communicationpreference-comp"
      data-component-name="o-account-communicationpreference"
    >
      <Modal
        show={profileModalId === COMMUNICATION_PREFERENCE}
        popupOpenState={profileModalId === COMMUNICATION_PREFERENCE}
        setPopupOpenState={(): void => {
          clearModalStates();
        }}
        modalId={props?.model?.modelId + 'modal'}
        labelledBy={props?.model?.modelId}
        onClose={(): void => {
          clearModalStates();
        }}
        secondaryClassName="modal__container  communication-preference"
        disableScrollOnBody={true}
        aria-modal="true"
        data-testid="communication"
        role="dialog"
        handleBlur={true}
      >
        <Modal.Header
          className="communication-model-header t-subtitle-xl "
          customHeadingClass="t-subtitle-xl"
          labelText={props?.model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            clearModalStates();
          }}
          data-testid="communication-model-header"
        />
        <Modal.Body className={`modal__container-content py-0 ${modalBodyClass}`}>
          <div className="px-2 py-3">
            {savedConsents.length === 0 ? (
              <div className="container modal_loader px-0 py-4 px-md-5">
                <div className="col ">
                  <div className="skeleton-loader modal_loader__heading " />
                  <div className="skeleton-loader modal_loader__body mt-3" />
                  <div className="skeleton-loader modal_loader__body mt-3" />
                </div>
              </div>
            ) : (
              <div className="">
                {(hasError || isConsentError) && (
                  <div id={'communication-preferences'} data-testId="uxl-error-msg" className="uxl-error-msg">
                    <Messages messageType="warning" className="mb-4">
                      <RichText
                        text={
                          isConsentError ? props?.model?.personalizedConsentErrorMessage : pageContext?.uxlErrorMessage
                        }
                        componentId="uxl-error-msg"
                      />
                    </Messages>
                  </div>
                )}

                {props.model?.communicationPreferencesDetails?.map((item, index) => {
                  if (item.hideForCountry && item.hideForCountry === userCountryCode) {
                    return '';
                  } else
                    return (
                      <div className="pt-4" key={item.title} role="group" aria-labelledby={`checkbox-consent-${index}`}>
                        <div id={`checkbox-consent-${index}`}>
                          <Heading
                            fontSize={Types?.size?.medium}
                            titleText={item.title}
                            customClass={'communication-model__offers__heading '}
                            variation={Types?.headingType?.subtitle}
                          />
                          <Text
                            fontSize={Types?.size?.small}
                            element={Types?.tags?.paragraph}
                            copyText={item.subTitle}
                          />
                        </div>
                        <div className="pt-4 pb-2">
                          {item.communicationPreferencesCheckBoxes.length > 0 &&
                            item.communicationPreferencesCheckBoxes?.map(option => (
                              <CheckBox
                                key={option.type}
                                data-testid={`checkbox-${option.type}-${option.method}`}
                                checkboxName={`notification-option-${option.type}`}
                                checkboxId={`${option.type}-checkbox`}
                                className="notification-option pb-2"
                                checkboxLabel={option.description}
                                checked={findSavedConsents(option)?.subscribed || false}
                                onChange={() => handleCheckboxChange(option)}
                                onKeyDown={event => event.key === 'Enter' && handleCheckboxChange(option)}
                              />
                            ))}
                        </div>
                        <hr className="modal-main-container__hrLine" />
                      </div>
                    );
                })}
                {koreaUserConsents && userCountryCode === KOREA_COUNTRY_CODE ? (
                  <>
                    <div className="py-4">
                      <Heading
                        fontSize={Types?.size?.medium}
                        titleText={props?.model?.personalizedHeader}
                        customClass={'communication-model__offers__heading '}
                        variation={Types?.headingType?.subtitle}
                      />
                      <Text
                        fontSize={Types?.size?.small}
                        element={Types?.tags?.paragraph}
                        copyText={props?.model.personalizedDescription}
                      />
                      {koreaUserConsents?.map(option => {
                        const sanitizedNode = DOMPurify.sanitize(option.checkboxDescription, { RETURN_DOM: true });
                        const fragmentString = sanitizedNode.innerHTML;
                        return option?.type === KOREA_COUNTRY_CODE &&
                          [...KOREAN_PERSONAL_CODES].includes(option.key) ? (
                          <div className="pt-3 korean-consents-checkbox">
                            <CheckBox
                              key={option.key}
                              data-testid={`checkbox-${option.type}`}
                              checkboxName={`personnel-option-${option.type}`}
                              checkboxId={`${option.type}-${option.key}-checkbox`}
                              className="notification-option"
                              checkboxLabel={option.checkboxTitle}
                              onKeyDown={event => event.key === 'Enter'}
                              checked={personalConsent.findIndex(key => key.type === option.key && key.value) !== -1}
                              onChange={() => onPersonnelConsentFormChange(option.key)}
                            />
                            <div className="ml-5 mb-4 t-font-m">
                              {!showMoreStates[option.key] ? (
                                `${option.checkboxDescription?.substring(3, 99)} `
                              ) : (
                                <p dangerouslySetInnerHTML={{ __html: fragmentString }}></p>
                              )}
                              <button
                                className="modal-main-container__content-showMoreBtn t-font-m m-link p-0"
                                onClick={() => {
                                  setShowMoreStates({
                                    ...showMoreStates,
                                    [option.key]: !showMoreStates[option.key],
                                  });
                                }}
                              >
                                <strong>
                                  {showMoreStates[option?.key] ? consentShowLessLabe : consentShowMoreLabe}
                                </strong>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                    <hr className="modal-main-container__hrLine mb-3" />
                  </>
                ) : (
                  <></>
                )}
                <div className="pt-4">
                  <div
                    className="m-input-field is-active item modal-main-container__languageSection"
                    onClick={e => e.stopPropagation()}
                  >
                    <label
                      className="dropdown__languageLabel label"
                      htmlFor="dropdown-label-language-filter"
                      id="dropdown-label-language-filter"
                    >
                      {props?.model?.languageLabel}
                    </label>
                    <Dropdown
                      dropdownOptions={languageDropdownOptions || []}
                      defaultOption={preferredLanguage?.value || defaultLanguageOption?.value}
                      customClassName="languageFilter d-flex align-items-center m-button-filter text-nowrap is-active"
                      dropdownId="language-filter"
                      isIconChange={false}
                      onChange={handleDropdownChange}
                    />
                    {(preferredLanguage?.id || defaultLanguageOption.id) !== constants.DEFAULT_LANG && (
                      <Text
                        copyText={props?.model?.languagePreferenceInlineText}
                        fontSize={Types.size.small}
                        customClass="t-font-xs mt-1"
                        element={Types.tags.paragraph}
                      />
                    )}
                  </div>

                  <RichText
                    text={props?.model?.termsAndConditionsSection}
                    customClass="termsandcondition pt-5 t-font-xs"
                    componentId="termsandcondition"
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          clearButtonLabel={props?.model?.cancelButton}
          applyLabel={props?.model?.saveButton}
          className="modal-main-container__content__modalFooter justify-content-md-end m-auto px-5 py-4 py-md-5"
          applyBtnClickTrackValue={COMMUNICATION_CLICK_TRACK}
          clearBtnClassname="modal-main-container__content__modalFooter__clear-btn m-link-action mr-md-4 p-0 px-auto px-md-0 align-items-center"
          clearClickHandler={(): void => {
            clearModalStates();
          }}
          applyBtnClickHandler={() => {
            updatePayload();
          }}
          applyButtonDisabled={isConsentError}
        ></Modal.Footer>
      </Modal>
    </StyledCommunicationPreference>
  );
};
export const CommunicationPreferenceConfig = {
  emptyLabel: 'communicationpreference',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/communicationpreference`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CommunicationPreferenceEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <CommunicationPreference {...props} />
  ) : (
    <EditableComponent config={CommunicationPreferenceConfig} {...props}>
      <CommunicationPreference {...props} />
    </EditableComponent>
  );
};
