import { FC, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Heading,
  Modal,
  RadioButton,
  Types,
  Calendar,
  baseVariables,
  Icon,
  InputTextField,
  Messages,
  RichText,
} from '@marriott/mi-ui-library';
import { Dropdown } from '../../molecules/Dropdown';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import clsx from 'clsx';
import axios from 'axios';
import { handleRedirect, addSubDirectoryPrefix } from '../../modules';
import {
  UPDATE_CUSTOMER_PROFILE,
  PROFILE_REDIRECTION_URL,
  ENCRYPTED_DATA,
  PROFILE_CONSTANTS,
  EDIT_PERSONAL_INFO_ID,
  COUNTRY_CODE_ADD_CARD_ZIPINPUT,
} from '../../modules/utils/constants/constants';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { phoenixAccountGetPersonalInformation } from '../../modules/graph';
import {
  checkForValidZipCode,
  cityValidationRegex,
  emailRegex,
  generateApolloClientHeaders,
  getCountryAndLangCode,
  getCurrentUrlParams,
  scrollToClass,
  stateValidationRegex,
} from '../../modules/utils';
import { PageContext } from '../../modules/context/PageContext';
import { FormField } from '../../molecules/FindReservation/FindReservation.types';
import {
  NIGHTS_LABEL,
  NIGHT_LABEL,
  PERSONAL_INFO_CLICK_TRACK,
  constants,
  OVERVIEW_CONSTANT,
  EditPersonalInfoModalErrorList,
} from '../../modules/utils/constants';
import { getCalednarLocaleKeys } from '../../modules/utils';
import { useProfileStore } from '../../modules/store/profileStore';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { BANNER_MSGS_KEYS } from '../../modules/utils/constants/constants';
import { StyledEditPersonalInformation } from './EditPersonalInformation.styles';
import {
  CustomerType,
  EditPersonalInformationTypes,
  FormData,
  FormErrors,
  SubmitPayload,
  PhoneDetail,
} from './EditPersonalInformation.types';
import { CustomError } from '../CreateAccountConsentsForm/CreateAccountConsentsForm.types';

export const EditPersonalInformation: FC<EditPersonalInformationTypes> = props => {
  const authorModelData = props?.model;
  const { IS_LOCAL_DEV } = process.env;
  const [getCustomerProfileDetails, { data: profileData, error: profileDataError, loading: profileLoading }] =
    useLazyQuery(phoenixAccountGetPersonalInformation);
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const modalBodyClass = 'edit-personal-modal-body';
  const { setRevisionToken, revisionToken } = useProfileStore(state => state);
  const [hasUXLError, setHasUXLError] = useState<boolean>(false);
  const { memberLevel } = useAccountPersistentStore(state => state);
  const initialState: FormData = {
    postalCode: '',
    addressLine: '',
    addressLine2: '',
    city: '',
    email: '',
    retypeEmail: '',
    province: '',
    homePhoneNumber: '',
    businessPhoneNumber: '',
    mobilePhoneNumber: '',
    country: '',
    homeCountryCode: '',
    businessCountryCode: '',
    mobileCountryCode: '',
    homeCountryName: '',
    businessCountryName: '',
    mobileCountryName: '',
    selectedGender: '',
    dob: '',
    weddingDate: '',
  };

  const [formData, setFormData] = useState<FormData>({ ...initialState });
  const [initialFormData, setInitialFormData] = useState({ ...initialState });
  const [showCheckInDate, setShowCheckInDate] = useState<boolean>(false);
  const [showWeddingDate, setShowWeddingDate] = useState<boolean>(false);
  const calendarInputRef = useRef<HTMLDivElement>(null);
  const [selectedCheckInDate, setSelectedCheckInDate] = useState<Date | undefined>();
  const [selectedWeddingDate, setSelectedWeddingDate] = useState<Date | undefined>();
  const { profileDetails, setProfileDetails, profileModalId, setProfileModalId, setUserBaseCountryCode } =
    useProfileStore(state => state);
  const [isLoading, setIsLoading] = useState(false);
  const [fullname, setFullname] = useState('');
  const [hasError, setHasError] = useState(false);
  const { setBannerMsgs, setBannerId } = useBannerMessagesStore();
  const currentLocale = pageContext?.currentLocale;
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const localeCountryCode = countryCode || constants.USA_COUNTRY_CODE;
  const [errorState, setErrorState] = useState<FormErrors>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [countryId, setCountryId] = useState('');
  const [memberLevelState, setMemberLevelState] = useState<string>('');
  const [dobFormFields, setDobFormFields] = useState<Record<string, FormField>>({
    checkInDate: {
      errorMessage: authorModelData?.dateOfBirthErrorMessage,
      id: 'checkInDate',
      label: authorModelData?.dateOfBirthLabel,
      showErrorMsg: false,
      value: '',
    },
  });
  const [weddingformFields, setWeddingFormFields] = useState<Record<string, FormField>>({
    checkInDate: {
      errorMessage: authorModelData?.weddingAnniversaryErrorMessage,
      id: 'weddingDate',
      label: authorModelData?.weddingAnniversaryLabel,
      showErrorMsg: false,
      value: '',
    },
  });
  const dateFromDaysAgo = moment().subtract(constants.ALLOWED_YEAR_THRESHOLD, 'years').toDate();
  const dayBefore = moment().endOf('year').toDate();

  const countryDialCodeList = useMemo(() => {
    return authorModelData?.countryRegion?.map(country => {
      return { ...country, value: country.countryDialCode, name: country.value };
    });
  }, [authorModelData?.countryRegion]);

  const hasSpecialCharsInPhone = (phoneNumber: string) => {
    return phoneNumber && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(phoneNumber);
  };

  const validateFormData = async (formData: FormData) => {
    // initially resetting all errors
    setHasUXLError(false);
    const newErrors: FormErrors = {};
    if (!formData.addressLine?.trim()) {
      newErrors.addressLine1 = authorModelData?.addressLine1ErrorMessage;
    }

    if (!formData.city?.trim()) {
      newErrors.city = authorModelData?.cityRequiredErrorMessage;
    } else if (!cityValidationRegex.test(formData?.city?.trim())) {
      newErrors.city = authorModelData?.cityInvalidErrorMessage;
    }

    if (!formData.email?.trim()) {
      newErrors.email = authorModelData?.emailRequiredErrorMessage;
    }
    if (!formData.retypeEmail?.trim()) {
      newErrors.retypeEmail = authorModelData?.errorMessageForInvalidEmail;
    }
    if (formData.email?.trim() && !emailRegex.test(formData.email?.trim())) {
      newErrors.emailInvalid = authorModelData?.emailInvalidErrorMessage;
    }
    if (formData.retypeEmail?.trim() && !emailRegex.test(formData.retypeEmail?.trim())) {
      newErrors.retypeEmailInvalid = authorModelData?.errorMessageForInvalidEmail;
    }
    if (formData.email?.trim() && formData.retypeEmail?.trim() && formData.email !== formData.retypeEmail?.trim()) {
      newErrors.emailNotMatching = authorModelData?.notMatchingEmailErrorMessage;
    }
    // do validation only if country is US or Canada
    const isUsOrCanada = COUNTRY_CODE_ADD_CARD_ZIPINPUT.includes(countryId);
    if (isUsOrCanada) {
      //zipcode validation
      if (!formData?.postalCode?.trim()) {
        newErrors.zipPostalCodeError = authorModelData?.zipPostalCodeError;
      } else if (!checkForValidZipCode(formData?.postalCode, countryId)) {
        newErrors.zipCodeInvalidError = authorModelData?.zipCodeInvalidError;
      }
      //state validation
      if (!formData?.province?.trim()) {
        newErrors.stateProvinceError = authorModelData?.stateProvinceError;
      }
      // only for non us country
      else if (countryId !== constants?.USA_COUNTRY_CODE && !stateValidationRegex.test(formData?.province?.trim())) {
        newErrors.stateProvinceError = authorModelData?.stateProvinceInvalidError;
      }
    }
    //state is mandatory for US and Canada, but we need to do regex validation if user enters any value
    else if (formData?.province?.trim() && !stateValidationRegex.test(formData?.province?.trim())) {
      newErrors.stateProvinceError = authorModelData?.stateProvinceInvalidError;
    }
    validatePhoneNumber(formData?.homePhoneNumber, 'Home', newErrors, formData?.homeCountryName);
    validatePhoneNumber(formData?.businessPhoneNumber, 'Business', newErrors, formData?.businessCountryName);
    validatePhoneNumber(formData?.mobilePhoneNumber, 'Mobile', newErrors, formData?.mobileCountryName);
    setErrorState(newErrors);
    setErrorsList(Object.values(newErrors));
    return Object.values(newErrors).some(value => value !== undefined);
  };

  const getPhonesDetails = () => {
    const phones: PhoneDetail[] = [];

    if (formData?.mobilePhoneNumber) {
      phones.push({
        countryCode:
          authorModelData?.countryRegion?.find(countrylist => countrylist?.value === formData?.mobileCountryName)?.id ??
          '',
        phoneNumber: formData?.mobilePhoneNumber,
        phoneType: PROFILE_CONSTANTS.PHONE_TYPE.DEFAULT,
      });
    }

    if (formData?.homePhoneNumber) {
      phones.push({
        countryCode:
          authorModelData?.countryRegion?.find(countrylist => countrylist?.value === formData?.homeCountryName)?.id ??
          '',
        phoneNumber: formData?.homePhoneNumber,
        phoneType: PROFILE_CONSTANTS.PHONE_TYPE.HOME,
      });
    }

    if (formData?.businessPhoneNumber) {
      phones.push({
        countryCode:
          authorModelData?.countryRegion?.find(countrylist => countrylist?.value === formData?.businessCountryName)
            ?.id ?? '',
        phoneNumber: formData?.businessPhoneNumber,
        phoneType: PROFILE_CONSTANTS.PHONE_TYPE.BUSINESS,
      });
    }

    return phones;
  };

  const updateProfileCountry = (isCountryUpdated: boolean, updatedCountryCode: string) => {
    const profileDetailsCopy = { ...profileDetails };
    const newProfileDetails = JSON.parse(JSON.stringify(profileDetails));
    if (isCountryUpdated) {
      const addressIndex = profileDetailsCopy?.profileAddress?.findIndex(address => address.primary === true);
      if (addressIndex > -1) {
        newProfileDetails.profileAddress[addressIndex].address.country.code = updatedCountryCode;
      }
      setUserBaseCountryCode(updatedCountryCode);
    }
    return newProfileDetails;
  };

  const onSubmit = async () => {
    const isFormInValid = await validateFormData(formData);
    if (isFormInValid) {
      setHasError(true);
      scrollToClass(modalBodyClass);
    } else {
      setHasError(false);
      const changedValues: { [key: string]: FormData } = {};
      for (const key in formData) {
        if ((formData as FormData)[key] !== (initialFormData as FormData)[key]) {
          changedValues[key] = formData[key];
        }
      }
      const updateProfileType: string[] = [];
      const addressKeys = ['country', 'city', 'postalCode', 'province', 'addressLine', 'addressLine2'];
      const phoneKeys = [
        'businessPhoneNumber',
        'homePhoneNumber',
        'mobilePhoneNumber',
        'homeCountryCode',
        'businessCountryCode',
        'mobileCountryCode',
      ];
      const emailKey = ['email'];
      const genderKey = ['selectedGender'];
      const dobKey = ['dob'];
      const weddingDateKey = ['weddingDate'];
      if (addressKeys.some(key => key in changedValues)) {
        updateProfileType.push('addresses');
      }
      if (phoneKeys.some(key => key in changedValues)) {
        updateProfileType.push('phones');
      }
      if (emailKey.some(key => key in changedValues)) {
        updateProfileType.push('emails');
      }
      if (genderKey.some(key => key in changedValues)) {
        updateProfileType.push('gender');
      }
      if (dobKey.some(key => key in changedValues)) {
        updateProfileType.push('dateOfBirth');
      }
      if (weddingDateKey.some(key => key in changedValues)) {
        updateProfileType.push('weddingAnniversary');
      }
      const payloadObject: SubmitPayload = {
        phones: getPhonesDetails(),
        emails: [
          {
            address: formData?.email,
            primary: true,
          },
        ],
        addresses: [
          {
            country:
              authorModelData?.countryRegion?.find(countrylist => countrylist?.value === formData?.country)?.id ?? '',
            city: formData?.city,
            line1Address: formData?.addressLine,
            line2Address: formData?.addressLine2,
            postalCode: formData?.postalCode,
            stateProvince:
              countryId === constants?.USA_COUNTRY_CODE
                ? authorModelData?.states?.find(state => state?.value === formData?.province)?.id ?? ''
                : formData?.province,
          },
        ],
        updateProfileType: updateProfileType,
        ...(formData?.weddingDate && { weddingAnniversary: moment(formData?.weddingDate).format('YYYY-MM-DD') }),
        dateOfBirth: formData?.dob,
        gender:
          formData?.selectedGender === PROFILE_CONSTANTS.PROFILE_GENDER.MALE
            ? PROFILE_CONSTANTS.GENDER.MALE
            : formData?.selectedGender === PROFILE_CONSTANTS.PROFILE_GENDER.FEMALE
            ? PROFILE_CONSTANTS.GENDER.FEMALE
            : PROFILE_CONSTANTS.GENDER.OTHER,
        revisionToken: revisionToken,
        returnUrl: '',
      };

      try {
        setIsLoading(true);
        const updateInfo = await updateCustomerProfile(payloadObject);
        if (updateInfo?.data?.isRedirect) {
          setBannerId(EDIT_PERSONAL_INFO_ID);
          sessionStorage.setItem(ENCRYPTED_DATA, updateInfo?.data?.encodePayload);
          const { nextStateURI } = updateInfo.data;
          handleRedirect(
            nextStateURI +
              `${nextStateURI?.includes('?') ? '&' : '?'}returnTo=${addSubDirectoryPrefix(PROFILE_REDIRECTION_URL)}`
          );
        } else {
          if (changedValues['country'] || updateProfileType.includes('phones')) {
            const updatedProfileDetail = updateProfileCountry(
              !!changedValues['country'],
              payloadObject.addresses[0].country
            );
            if (updateProfileType.includes('phones')) {
              updatedProfileDetail.phoneNumber = getPhonesDetails();
            }
            setProfileDetails({
              ...updatedProfileDetail,
            });
          }

          if (updateInfo?.data?.revisionTkn) {
            setFormData({ ...initialState });
            setRevisionToken(updateInfo?.data?.revisionTkn);
            setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
          }
          setProfileModalId('');
          setIsLoading(false);
        }
      } catch (error) {
        const errorKey = (error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages;
        // close modal and show error on profile page except uxl failure error messgae
        if (errorKey && !errorKey?.find((errorItem: string) => EditPersonalInfoModalErrorList?.includes(errorItem))) {
          setBannerMsgs(errorKey);
          setProfileModalId('');
        } else {
          setBannerMsgs('');
          setHasUXLError(true);
          scrollToClass(modalBodyClass);
        }
        setIsLoading(false);
      }
    }
  };
  const updateCustomerProfile = async (data: SubmitPayload) => {
    return axios.post(addSubDirectoryPrefix(UPDATE_CUSTOMER_PROFILE), data, {
      headers: {
        'Content-Type': 'application/json',
        Cookie: 'sessionID=' + sessionData?.sessionToken,
      },
    });
  };

  const handleDateChange = (selectedDate: Date) => {
    setSelectedCheckInDate(selectedDate);
    setFormData({ ...formData, dob: moment(selectedDate).format('MM-DD') });
    setDobFormFields(prevState => ({
      ...prevState,
      checkInDate: {
        ...prevState['checkInDate'],
        value: moment(selectedDate).format('MM-DD'),
        showErrorMsg: false,
      },
    }));
    setShowCheckInDate(false);
  };

  const handleWeddingDateChange = (selectedDate: Date) => {
    setSelectedWeddingDate(selectedDate);
    setFormData({ ...formData, weddingDate: moment(selectedDate).format('MM-DD-YYYY') });
    setWeddingFormFields(prevState => ({
      ...prevState,
      checkInDate: {
        ...prevState['checkInDate'],
        value: moment(selectedDate).format('MM-DD-YYYY'),
        showErrorMsg: false,
      },
    }));
    setShowWeddingDate(false);
  };

  const { shortWeekdays, shortWeekDaysName, longWeekDaysName, longMonthName, shortMonthName } =
    getCalednarLocaleKeys(authorModelData);

  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isPersonalInfoModal = param.get('editPersonalInformation');
    if (isPersonalInfoModal === 'true') {
      setProfileModalId(EDIT_PERSONAL_INFO_ID);
    }
  }, []);

  useEffect(() => {
    if (memberLevel) {
      setMemberLevelState(memberLevel);
    }
  }, [memberLevel]);

  //handle calendar to close if user clicks outside the calendar anywhere
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (calendarInputRef?.current && !calendarInputRef?.current?.contains(event.target as Node)) {
        setShowCheckInDate(false);
        setShowWeddingDate(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const isEqual = Object.entries(formData).every(([key, value]) => value === initialState[key]);
    if (profileModalId === EDIT_PERSONAL_INFO_ID && isEqual) {
      fetchCustomerData();
    }
  }, [profileModalId]);

  const getCountryDialCode = (data: CustomerType, phoneTypeCode: string, defaultFormData: FormData) => {
    const countryCode =
      data?.customer?.contactInformation?.phones?.find(
        (phone: { type: { code: string } }) => phone.type.code === phoneTypeCode
      )?.country?.code || data?.customer?.contactInformation?.addresses?.[0]?.address?.country?.code;

    const countryInfo = authorModelData?.countryRegion?.find((country: { id: string }) => country.id === countryCode);

    if (phoneTypeCode === constants.HOME_PHONE_CODE) {
      defaultFormData.homeCountryName = countryInfo?.value ?? constants.USA_COUNTRY;
      defaultFormData.homeCountryCode = countryInfo?.countryDialCode ?? constants.USA_COUNTRY_DIAL_CODE;
    } else if (phoneTypeCode === constants.BUSINESS_PHONE_CODE) {
      defaultFormData.businessCountryName = countryInfo?.value ?? constants.USA_COUNTRY;
      defaultFormData.businessCountryCode = countryInfo?.countryDialCode ?? constants.USA_COUNTRY_DIAL_CODE;
    } else {
      defaultFormData.mobileCountryName = countryInfo?.value ?? constants.USA_COUNTRY;
      defaultFormData.mobileCountryCode = countryInfo?.countryDialCode ?? constants.USA_COUNTRY_DIAL_CODE;
    }
  };

  const setPersonalInfoData = (data: CustomerType) => {
    setIsLoading(false);
    setHasUXLError(false);
    const weddingAnniversaryDate = data?.customer?.featuresByGroup?.ancillaryFeatures?.[0]?.value;
    const defaultFormData: FormData = initialState;
    defaultFormData.addressLine = data?.customer?.contactInformation?.addresses?.[0]?.address?.line1 || '';
    defaultFormData.addressLine2 = data?.customer?.contactInformation?.addresses?.[0]?.address?.line2 || '';
    defaultFormData.postalCode = data?.customer?.contactInformation?.addresses?.[0]?.address?.postalCode || '';
    defaultFormData.city = data?.customer?.contactInformation?.addresses?.[0]?.address?.city || '';
    defaultFormData.email = data?.customer?.contactInformation?.emails?.[0]?.address || '';
    defaultFormData.retypeEmail = data?.customer?.contactInformation?.emails?.[0]?.address || '';
    defaultFormData.country =
      authorModelData?.countryRegion?.find(
        (country: { id: string }) =>
          country.id === data?.customer?.contactInformation?.addresses?.[0]?.address?.country?.code
      )?.value || '';
    // default value of state is Alabama hence state[1]
    defaultFormData.province =
      data?.customer?.contactInformation?.addresses?.[0]?.address?.country?.code === constants?.USA_COUNTRY_CODE
        ? authorModelData?.states?.find(
            (state: { id: string }) =>
              state?.id === data?.customer?.contactInformation?.addresses?.[0]?.address?.stateProvince
          )?.value || authorModelData?.states?.[1]?.value
        : data?.customer?.contactInformation?.addresses?.[0]?.address?.stateProvince || '';
    defaultFormData.homePhoneNumber =
      data?.customer?.contactInformation?.phones?.find(
        (phone: { type: { code: string } }) => phone.type.code === constants.HOME_PHONE_CODE
      )?.number || '';
    defaultFormData.businessPhoneNumber =
      data?.customer?.contactInformation?.phones?.find(
        (phone: { type: { code: string } }) => phone.type.code === constants.BUSINESS_PHONE_CODE
      )?.number || '';
    defaultFormData.mobilePhoneNumber =
      data?.customer?.contactInformation?.phones?.find(
        (phone: { type: { code: string } }) => phone.type.code === constants.MOBILE_PHOHE_CODE
      )?.number || '';
    getCountryDialCode(data, constants.HOME_PHONE_CODE, defaultFormData);
    getCountryDialCode(data, constants.BUSINESS_PHONE_CODE, defaultFormData);
    getCountryDialCode(data, constants.MOBILE_PHOHE_CODE, defaultFormData);
    defaultFormData.selectedGender = data?.customer?.profile?.gender?.code || '';
    defaultFormData.dob = data?.customer?.profile?.birthday || '';
    defaultFormData.weddingDate = weddingAnniversaryDate ? moment(weddingAnniversaryDate).format('MM-DD-YYYY') : '';

    setFormData(defaultFormData);
    setInitialFormData(defaultFormData);
    setFullname(data?.customer?.profile?.name?.fullName);
    setCountryId(
      authorModelData?.countryRegion?.find(
        (country: { id: string }) =>
          country.id === data?.customer?.contactInformation?.addresses?.[0]?.address?.country?.code
      )?.id || ''
    );
  };

  const resetData = () => {
    setFormData({ ...initialState });
    setInitialFormData({ ...initialState });
    setHasUXLError(false);
    setHasError(false);
  };

  useEffect(() => {
    if (profileData) {
      setPersonalInfoData(profileData);
    } else if (profileDataError) {
      setIsLoading(false);
      setHasUXLError(true);
      scrollToClass(modalBodyClass);
    }
  }, [profileData, profileDataError]);

  const fetchCustomerData = () => {
    setIsLoading(true);
    getCustomerProfileDetails({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    });
  };

  const handlePhoneNumberChange = (event: string, phoneType: string) => {
    const phoneNumber = event;
    setFormData({ ...formData, [`${phoneType}PhoneNumber`]: phoneNumber });

    const hasSpecialCharacters = hasSpecialCharsInPhone(phoneNumber);
    setErrorState(prevState => ({
      ...prevState,
      [`${phoneType}SpecialChar`]: hasSpecialCharacters,
    }));
  };

  const validatePhoneNumber = (phoneNumber: string, phoneType: string, newErrors: FormErrors, country?: string) => {
    const validLength =
      country === 'USA' ? phoneNumber.length === 10 : phoneNumber.length >= 7 && phoneNumber.length <= 16;

    if (phoneType === 'Home' && phoneNumber && !validLength) {
      newErrors.homePhoneError = authorModelData?.wrongHomeNumberErrorMessage;
    } else if (phoneType === 'Business' && phoneNumber && !validLength) {
      newErrors.businessPhoneError = authorModelData?.wrongBusinessNumberErrorMessage;
    } else if (phoneType === 'Mobile' && phoneNumber && !validLength) {
      newErrors.mobilePhoneError = authorModelData?.wrongMobileNumberErrorMessage;
    }
  };

  return (
    <StyledEditPersonalInformation
      data-testid="editpersonalinformation"
      data-component-name="o-account-editpersonalinformation"
    >
      <Modal
        show={profileModalId === EDIT_PERSONAL_INFO_ID}
        popupOpenState={profileModalId === EDIT_PERSONAL_INFO_ID}
        setPopupOpenState={(): void => {
          setProfileModalId('');
        }}
        secondaryClassName="modal__container"
        role="dialog"
        aria-label="Edit-personal-info"
        onClose={(): void => {
          setProfileModalId('');
          resetData();
        }}
        className="modal-container custom-scrollbar"
        handleBlur={true}
      >
        <Modal.Header
          labelText={authorModelData?.personalInfoHeader}
          popupHeaderOnCLoseFunc={(): void => {
            setProfileModalId('');
            resetData();
          }}
          customHeadingClass="t-subtitle-xl"
        />
        <Modal.Body className={`px-4 px-md-5 py-2 py-md-3 custom-scrollbar ${modalBodyClass}`}>
          {profileModalId === EDIT_PERSONAL_INFO_ID ? (
            <div>
              {hasUXLError && (
                <Messages messageType="warning" className="mb-4">
                  <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                </Messages>
              )}
              {hasError && (
                <div className="mt-3 mb-4" data-testId="all-empty">
                  <div className="m-message-inline error-sev1">
                    <div className="m-message-content-wrap">
                      <div className="m-message-content">
                        <span>{authorModelData?.errMsgCommonHeader}</span>
                        {errorsList.map(error => (
                          <ul>
                            <li>{error}</li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="edit-personal-info-container text_modal-align container"
                data-testId="edit-personal-container"
              >
                {fullname && (
                  <Heading
                    titleText={fullname.toLowerCase()}
                    variation={Types.headingType.subtitle}
                    fontSize={Types.size.large}
                    customClass="modal__container__fullname"
                  />
                )}
                <RichText
                  text={
                    localeCountryCode === constants.USA_COUNTRY_CODE
                      ? authorModelData?.changeYourName
                      : authorModelData?.changeYourNameForNonUS
                  }
                  componentId="change-your-name"
                  customClass="t-font-s pt-2"
                />
                <hr className="my-4" />
                <div className="col-12 px-0">
                  <div className="form-container justify-content-between">
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* Country/region dropdown  */}
                      <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
                        <label
                          className="countryRegion-label label"
                          htmlFor="dropdown-label-country-region"
                          id="dropdown-label-country-region"
                        >
                          {authorModelData?.countryRegionLabel}
                        </label>
                        <Dropdown
                          defaultOption={formData?.country}
                          dropdownOptions={authorModelData?.countryRegion ?? []}
                          customClassName={'is-active'}
                          onChange={event => {
                            setCountryId(event?.id);
                            setFormData({
                              ...formData,
                              province:
                                event?.id === constants?.USA_COUNTRY_CODE ? authorModelData?.states?.[1]?.value : '',
                              country: event.value,
                            });
                          }}
                          dropdownId="country-region"
                        />
                      </div>
                      {/* zipcode  */}
                      {!formData?.country.includes('China') && (
                        <div className="m-input-field is-active item">
                          <InputTextField
                            label={authorModelData?.zipPostalCodeLabel}
                            testId="zipcode"
                            inputValue={formData.postalCode}
                            assignInputValue={true}
                            setErrorHtml={true}
                            messageClass="error-label"
                            inputMaxLength={10}
                            className="input-zipcode"
                            getInputValue={(val: string) => {
                              setFormData({ ...formData, postalCode: val });
                            }}
                            getLabelProps={() => ({
                              htmlFor: 'zipcode',
                            })}
                            getInputProps={() => ({
                              autoComplete: 'off',
                              id: 'zipcode',
                            })}
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* address line 1*/}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.addressLineOneLabel}
                          testId="addressLine1"
                          inputValue={formData.addressLine}
                          setErrorHtml={true}
                          messageClass="error-label"
                          className="input-addressLine"
                          inputMaxLength={32}
                          getInputValue={(val: string) => {
                            setFormData({ ...formData, addressLine: val });
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'addressLine1',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'addressLine1',
                          })}
                        />
                      </div>
                      {/* address line 2  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.addressLineTwoLabel}
                          testId="addressLine2"
                          inputValue={formData.addressLine2}
                          setErrorHtml={true}
                          className="input-addressLine2"
                          messageClass="error-label"
                          inputMaxLength={32}
                          getInputValue={(val: string) => {
                            setFormData({ ...formData, addressLine2: val });
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'addressLine2',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'addressLine2',
                          })}
                        />
                      </div>
                    </div>
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* city  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.cityLabel}
                          testId="city"
                          inputValue={formData.city}
                          setErrorHtml={true}
                          className="input-city"
                          messageClass="error-label"
                          inputMaxLength={21}
                          getInputValue={(val: string) => {
                            setFormData({ ...formData, city: val });
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'city',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'city',
                          })}
                        />
                      </div>
                      {/* province  */}
                      {countryId === constants?.USA_COUNTRY_CODE ? (
                        <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
                          <label
                            className="country-province-label label"
                            htmlFor="dropdown-label-country-province"
                            id="dropdown-label-country-province"
                          >
                            {authorModelData?.stateProvinceLabel}
                          </label>
                          {/* default value of state is Alabama hence state[1] */}
                          <Dropdown
                            defaultOption={
                              authorModelData?.states?.find(state => state?.value === formData?.province)
                                ? formData?.province
                                : authorModelData?.states?.[1]?.value
                            }
                            dropdownOptions={authorModelData?.states ?? []}
                            onChange={event => setFormData({ ...formData, province: event.value })}
                            customClassName={'is-active'}
                            dropdownId="country-province"
                          />
                        </div>
                      ) : (
                        <div className="m-input-field is-active item">
                          <InputTextField
                            label={authorModelData?.stateProvinceLabel}
                            testId="country-province"
                            inputValue={formData?.province}
                            className="province"
                            setErrorHtml={true}
                            inputMaxLength={4}
                            messageClass="error-label"
                            getInputValue={(val: string) => {
                              setFormData({ ...formData, province: val });
                            }}
                            getLabelProps={() => ({
                              htmlFor: 'dropdown-label-hotel-country',
                            })}
                            getInputProps={() => ({
                              autoComplete: 'off',
                              id: 'dropdown-label-hotel-country',
                            })}
                          />
                        </div>
                      )}
                    </div>
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* email field*/}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.emailLabel}
                          testId="email"
                          inputValue={formData.email}
                          setErrorHtml={true}
                          className="input-email"
                          messageClass="error-label"
                          inputMaxLength={80}
                          getInputValue={(val: string) => {
                            setFormData({ ...formData, email: val });
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'email',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'email',
                          })}
                        />
                      </div>
                      {/* re-enter email  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.reEnterEmailLabel}
                          testId="emailConfirm"
                          inputValue={formData.retypeEmail}
                          setErrorHtml={true}
                          className="input-retypeEmail"
                          messageClass="error-label"
                          inputMaxLength={80}
                          getInputValue={(val: string) => {
                            setFormData({ ...formData, retypeEmail: val });
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'emailConfirm',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'emailConfirm',
                          })}
                        />
                      </div>
                    </div>
                    {/* Mobile Phone Number */}
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* country code dropdown*/}
                      <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
                        <label
                          className="countryCode-label label m-ellipsis-1line numberfieldwidth"
                          htmlFor="dropdownmobile-phone-number"
                        >
                          {authorModelData?.businessPhoneCountryCodeLabel}
                        </label>
                        <Dropdown
                          defaultOption={formData?.mobileCountryCode}
                          onChange={event =>
                            setFormData({ ...formData, mobileCountryCode: event.value, mobileCountryName: event.name })
                          }
                          dropdownOptions={countryDialCodeList ?? []}
                          customClassName={'is-active'}
                          dropdownId="mobile-phone-number"
                          showIdandValueinOptions={true}
                        />
                      </div>
                      {/* mobile phone number field  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.mobilePhoneNumber}
                          testId="mobilephone"
                          inputValue={formData.mobilePhoneNumber}
                          setErrorHtml={true}
                          className="input-mobilePhone"
                          messageClass="error-label"
                          inputMaxLength={16}
                          getInputValue={(val: string) => {
                            handlePhoneNumberChange(val, 'mobile');
                          }}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'phoneNumber',
                          })}
                          getLabelProps={() => ({
                            htmlFor: 'phoneNumber',
                          })}
                        />
                        {errorState?.mobileSpecialChar && (
                          <div className="t-label-s validation-msg">
                            {authorModelData?.inlineSpecialCharNumberError}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Home Phone Number */}
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* country code dropdown*/}
                      <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
                        <label
                          className="countryCode-label label m-ellipsis-1line numberfieldwidth"
                          htmlFor="dropdownhome-phone-number"
                        >
                          {authorModelData?.homePhoneCountryCodeLabel}
                        </label>
                        <Dropdown
                          defaultOption={formData?.homeCountryCode}
                          dropdownOptions={countryDialCodeList ?? []}
                          onChange={event =>
                            setFormData({ ...formData, homeCountryCode: event.value, homeCountryName: event.name })
                          }
                          customClassName={'is-active'}
                          dropdownId="home-phone-number"
                          showIdandValueinOptions={true}
                        />
                      </div>
                      {/* home phone number field  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.homePhoneLabel}
                          testId="homephone"
                          inputValue={formData.homePhoneNumber}
                          setErrorHtml={true}
                          messageClass="error-label"
                          inputMaxLength={16}
                          className="input-homePhone"
                          getInputValue={(val: string) => {
                            handlePhoneNumberChange(val, 'home');
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'homephoneNumber',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'homephoneNumber',
                          })}
                        />
                        {errorState?.homeSpecialChar && (
                          <div className="t-label-s validation-msg">
                            {authorModelData?.inlineSpecialCharNumberError}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Business Phone Number */}
                    <div className="d-flex spacing mb-5 flex-column flex-md-row">
                      {/* country code dropdown*/}
                      <div className="m-input-field is-active item" onClick={e => e.stopPropagation()}>
                        <label
                          className="countryCode-label label m-ellipsis-1line numberfieldwidth"
                          htmlFor="dropdownbusiness-phone-number"
                        >
                          {authorModelData?.businessPhoneCountryCodeLabel}
                        </label>
                        <Dropdown
                          defaultOption={formData?.businessCountryCode}
                          onChange={event =>
                            setFormData({
                              ...formData,
                              businessCountryCode: event.value,
                              businessCountryName: event.name,
                            })
                          }
                          dropdownOptions={countryDialCodeList ?? []}
                          customClassName={'is-active'}
                          dropdownId="business-phone-number"
                          showIdandValueinOptions={true}
                        />
                      </div>
                      {/* business phone number field  */}
                      <div className="m-input-field is-active item">
                        <InputTextField
                          label={authorModelData?.businessPhoneLabel}
                          testId="businessphone"
                          inputValue={formData.businessPhoneNumber}
                          setErrorHtml={true}
                          className="input-businessPhone"
                          messageClass="error-label"
                          inputMaxLength={16}
                          getInputValue={(val: string) => {
                            handlePhoneNumberChange(val, 'business');
                          }}
                          getLabelProps={() => ({
                            htmlFor: 'businessphoneNumber',
                          })}
                          getInputProps={() => ({
                            autoComplete: 'off',
                            id: 'businessphoneNumber',
                          })}
                        />
                        {errorState?.businessSpecialChar && (
                          <div className="t-label-s validation-msg">
                            {authorModelData?.inlineSpecialCharNumberError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex datapicker spacing mb-5 flex-column flex-md-row" ref={calendarInputRef}>
                      <div
                        className={clsx(
                          'px-0',
                          OVERVIEW_CONSTANT.AMBASSADOR_CODE.includes(memberLevelState) ? 'w-china' : 'w-intl'
                        )}
                      >
                        {Object.values(dobFormFields)?.map(field => {
                          const isCheckInDateField = field?.id === 'checkInDate';

                          return (
                            <div className={clsx('datepicker-inner mt-1 col-12')} key={field.id}>
                              <div className={clsx('m-input-field', field?.showErrorMsg ? 'is-error' : 'is-active')}>
                                <label className="date-label" htmlFor={field?.id}>
                                  {field?.label}
                                </label>
                                <input
                                  type="text"
                                  placeholder={constants.DOB_DATE_PLACEHOLDER}
                                  data-testid={field?.id}
                                  name={field?.id}
                                  id={field?.id}
                                  value={formData?.dob}
                                  disabled={!!profileData?.customer?.profile?.birthday}
                                  onChange={e => {
                                    const value = e.target.value;
                                    const { id } = field ?? {};
                                    const fields = { ...dobFormFields };
                                    fields[id].value = value;
                                    fields[id].showErrorMsg = false;
                                    setDobFormFields(fields);
                                  }}
                                  onClick={() => isCheckInDateField && setShowCheckInDate(true)}
                                  onKeyDown={() => isCheckInDateField && setShowCheckInDate(true)}
                                  autoComplete="off"
                                  readOnly={isCheckInDateField}
                                  role="textbox"
                                  maxLength={field.id === 'confirmationNo' ? 24 : undefined}
                                />
                                {field?.showErrorMsg && <span>{field?.errorMessage}</span>}
                                {isCheckInDateField && (
                                  <span
                                    data-testid="calendarDay"
                                    className={clsx('icon-calendar icon-m', {
                                      'icon-disabled': profileData?.customer?.profile?.birthday,
                                    })}
                                    onClick={() => setShowCheckInDate(!showCheckInDate)}
                                  ></span>
                                )}
                              </div>
                              {showCheckInDate && isCheckInDateField && (
                                <Suspense fallback={<>loading</>}>
                                  <div className={clsx('calendar-wrapper mb-3')} onClick={e => e.stopPropagation()}>
                                    <Calendar
                                      variation={'DESKTOP'}
                                      startDate={moment(selectedCheckInDate)}
                                      endDate={null}
                                      disableHover={true}
                                      focusedInput={'customDate'}
                                      setFocusedInputHandler={(): void => {}}
                                      disableHoverToolTip={true}
                                      customDatesFlag={true}
                                      nightsLabel={NIGHTS_LABEL}
                                      nightLabel={NIGHT_LABEL}
                                      maxAllowedDays={365}
                                      weekdays={shortWeekdays}
                                      isPastDateSelectionDisabled={false}
                                      weekdaysLong={longWeekDaysName}
                                      monthsShort={shortMonthName}
                                      weekdaysShort={shortWeekDaysName}
                                      months={longMonthName}
                                      defaultMonth={new Date()}
                                      disabledDays={[]}
                                      onDayClickEvent={day => handleDateChange(day)}
                                      numberOfMonths={1}
                                      firstDayOfWeek={authorModelData?.firstDayOfWeek || '0'}
                                      showOutsideDays={true}
                                      setDateHandler={() => {}}
                                    />
                                  </div>
                                </Suspense>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {OVERVIEW_CONSTANT.AMBASSADOR_CODE.includes(memberLevelState) && (
                        <div
                          className={clsx(
                            'px-0',
                            OVERVIEW_CONSTANT.AMBASSADOR_CODE.includes(memberLevelState) ? 'w-china' : 'w-intl'
                          )}
                        >
                          {Object.values(weddingformFields)?.map(field => {
                            const isCheckInDateField = field?.id === 'weddingDate';

                            return (
                              <div className="datepicker-inner mt-1 col-12" key={field?.id}>
                                <div className={clsx('m-input-field ', field?.showErrorMsg ? 'is-error' : 'is-active')}>
                                  <label className="date-label" htmlFor={field?.id}>
                                    {field?.label}
                                  </label>
                                  <input
                                    type="text"
                                    placeholder={constants.WEDDING_DATE_PLACEHOLDER}
                                    data-testid="weddingDate"
                                    name={field?.id}
                                    id={field?.id}
                                    style={{ caretColor: baseVariables.color['base10'] }}
                                    value={formData?.weddingDate}
                                    onChange={e => {
                                      const value = e.target.value;
                                      const { id } = field ?? {};
                                      const fields = { ...weddingformFields };
                                      fields[id].value = value;
                                      fields[id].showErrorMsg = false;
                                      setWeddingFormFields(fields);
                                    }}
                                    onClick={() => isCheckInDateField && setShowWeddingDate(true)}
                                    onKeyDown={() => isCheckInDateField && setShowWeddingDate(true)}
                                    autoComplete="off"
                                    readOnly={isCheckInDateField}
                                    role="textbox"
                                    maxLength={field?.id === 'confirmationNo' ? 24 : undefined}
                                  />
                                  {field?.showErrorMsg && <span>{field?.errorMessage}</span>}
                                  {isCheckInDateField && (
                                    <span
                                      data-testid="calendarDay"
                                      className={'icon-calendar icon-m'}
                                      onClick={() => setShowWeddingDate(!showWeddingDate)}
                                    ></span>
                                  )}
                                </div>
                                {showWeddingDate && isCheckInDateField && (
                                  <Suspense fallback={<>loading</>}>
                                    <div className={clsx('calendar-wrapper mb-3')} onClick={e => e.stopPropagation()}>
                                      <Calendar
                                        variation={'DESKTOP'}
                                        startDate={moment(selectedWeddingDate)}
                                        endDate={null}
                                        focusedInput={'customDate'}
                                        setFocusedInputHandler={(): void => {}}
                                        disableHoverToolTip={true}
                                        customDatesFlag={true}
                                        nightsLabel={NIGHTS_LABEL}
                                        nightLabel={NIGHT_LABEL}
                                        maxAllowedDays={0}
                                        weekdays={shortWeekdays}
                                        weekdaysLong={longWeekDaysName}
                                        monthsShort={shortMonthName}
                                        weekdaysShort={shortWeekDaysName}
                                        months={longMonthName}
                                        defaultMonth={new Date()}
                                        disabledDays={[]}
                                        onDayClickEvent={day => handleWeddingDateChange(day)}
                                        customFromDate={dateFromDaysAgo}
                                        customToDate={dayBefore}
                                        numberOfMonths={1}
                                        firstDayOfWeek={authorModelData?.firstDayOfWeek || '0'}
                                        showOutsideDays={true}
                                        setDateHandler={() => {}}
                                      />
                                    </div>
                                  </Suspense>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {/* gender selection  */}
                    <div className="account-selection mb-4">
                      <div className="t-subtitle-m mb-3">{authorModelData?.genderLabel}</div>
                      <div className="personal-info-radiobtn">
                        <div className="m-radio-button-sm mb-2">
                          <RadioButton
                            data-testid="maleRadioButton"
                            radiobuttonId="yes"
                            radiobuttonName="accountSelection"
                            radiobuttonLabel={authorModelData?.maleRadioButtonLabel}
                            checked={formData.selectedGender === PROFILE_CONSTANTS.PROFILE_GENDER.MALE}
                            onChange={() =>
                              setFormData({ ...formData, selectedGender: PROFILE_CONSTANTS.PROFILE_GENDER.MALE })
                            }
                            value="true"
                            customClickClass={''}
                            setTabIndex={0}
                            custom_click_track_value={''}
                            labelClassName="personal-info-radiobtn__label t-font-m mb-0"
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              setFormData({ ...formData, selectedGender: PROFILE_CONSTANTS.PROFILE_GENDER.MALE })
                            }
                          />
                        </div>
                        <div className="m-radio-button-sm mb-2">
                          <RadioButton
                            data-testid="femaleRadioButton"
                            radiobuttonId="no"
                            radiobuttonName="accountSelection"
                            radiobuttonLabel={authorModelData?.femaleRadioButtonLabel}
                            checked={formData.selectedGender === PROFILE_CONSTANTS.PROFILE_GENDER.FEMALE}
                            onChange={() =>
                              setFormData({ ...formData, selectedGender: PROFILE_CONSTANTS.PROFILE_GENDER.FEMALE })
                            }
                            value="false"
                            customClickClass={''}
                            setTabIndex={0}
                            custom_click_track_value={''}
                            labelClassName="t-font-m"
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              setFormData({ ...formData, selectedGender: PROFILE_CONSTANTS.PROFILE_GENDER.FEMALE })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <Icon iconClass="icon-smartphone icon-m icon-decorative pr-2"></Icon>
                      <RichText
                        text={authorModelData?.mobileAppDescriptionLabel}
                        componentId={''}
                        customClass={'t-font-s'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto py-4 px-5 py-md-5 align-items-sm-baseline justify-content-md-end"
          clearButtonLabel={authorModelData?.cancel}
          clearClickHandler={(): void => {
            setProfileModalId('');
            resetData();
          }}
          applyLabel={authorModelData?.submitButtonLabel}
          data-testid="submitbtn"
          clearBtnClassname="submit-btn-footer t-font-s mr-md-4"
          applyButtonDisabled={profileLoading || isLoading}
          applyBtnClickHandler={() => {
            onSubmit();
          }}
          applyBtnClickTrackValue={PERSONAL_INFO_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledEditPersonalInformation>
  );
};
export const EditPersonalInformationConfig = {
  emptyLabel: 'editpersonalinformation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/editpersonalinformation`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditPersonalInformationEditable = (authorModelData: any) => {
  return authorModelData?.cqPath?.includes('datapagecomponent') ? (
    <EditPersonalInformation {...authorModelData} />
  ) : (
    <EditableComponent config={EditPersonalInformationConfig} {...authorModelData}>
      <EditPersonalInformation {...authorModelData} />
    </EditableComponent>
  );
};
